@import 'src/resources';

$height: 24px;
$button-padding: 1rem;

.ogp-dropdown {
  margin: 0.25em 0;
}

.ogp-dropdown__input-container {
  @include button-unstyled;
  @include input-container-style;
  width: 100%;

  position: relative;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus > div {
    background-color: $ut-limestone--t20;
    border-color: $ut-charcoal--s40;
  }
}

.ogp-dropdown__menu-container {
  position: relative;

  &:hover,
  &:focus,
  &:active {
    border-color: $ut-charcoal--s40;

    background-color: $ut-limestone--t20;
    outline: none;
  }
}

.ogp-dropdown__input {
  @include input-field-style;
  @include no-wrap;

  padding-right: calc(#{$height} + #{$button-padding});
  display: flex;
  align-items: center;
  color: $ut-charcoal;

  &--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--light-text {
    color: $ut-shade--s20;
  }

  &:focus,
  &:focus-within {
    border-color: $ut-charcoal--s40;
    background-color: $ut-limestone--t20;
    outline: none;

    & > div {
      border-color: $ut-charcoal--s40;
    }
  }
}

.ogp-dropdown__label {
  @include ut-label;
  @include no-wrap;
}

.ogp-dropdown__arrow-container {
  @include button-unstyled;

  width: $height;
  height: $height;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  right: 1rem;
  top: calc(50% - #{$height / 2});
  pointer-events: none;
}

.ogp-dropdown__arrow {
  @include arrow-up($ut-shade--s40);
  transform: rotateZ(180deg);
  transition: transform $transition-time;

  &--open {
    transform: initial;
  }
}

.ogp-dropdown__menu {
  position: absolute;
  width: 100%;
  background: $ut-white;
  max-height: 300px;
  overflow: scroll;
  padding: 0;

  &--open {
    z-index: $z-index-dropdown-menu;
    border: 1px solid $ut-shade--s40;
    border-top: none;
    border-radius: 0 0 3px 3px;
  }

  &--empty {
    display: none;
  }

  &:hover,
  &:focus {
    outline: none !important;
  }
}

.ogp-dropdown__item {
  padding: 12px;
  cursor: pointer;

  &--highlighted {
    background: $ut-bluebonnet;
    color: $ut-white;
  }

  &--selected {
    font-weight: 900;
  }
}
