@import 'src/resources';

.checkout-form__container {
  position: relative;
}

.checkout-form__donor-container {
  @include widthColumnLeft;

  padding: $content-padding-vertical $page-margin-horizontal $footer-height
    $page-margin-horizontal;

  @include desktop-small {
    padding: $content-padding-vertical $page-margin-horizontal-mobile;
    padding-bottom: $footer-height + $content-padding-vertical;
  }
}
.checkout-form__gift-summary-container {
  @include widthColumnRight;
  padding: 0 0 0 ($page-margin-horizontal / 2);
}

.checkout-form__back-link {
  @include ut-link;
  @include ut-body;
  display: block;

  color: $ut-burntorange;
  cursor: pointer;
  text-decoration: underline;
  padding-top: 40px;
}
