@import 'src/resources';

.transaction-modal__window {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  min-width: 400px;

  @include mobile {
    width: 90vw;
    min-width: 0;
  }
}

.transaction-modal {
  &__title {
    text-align: center;
    padding-bottom: 20px;
  }

  &__gif-container {
    display: flex;
    justify-content: center;
  }

  &__gif {
    height: 100%;
    width: auto;
    max-height: 300px;
    max-width: 300px;
  }
}
