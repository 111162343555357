$left-icon-padding: 15px;
$right-icon-padding: 20px;

@mixin input-container-with-icon($filename, $padding, $rightSide) {
  &:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    width: $icon-width;
    height: 100%;
    z-index: 1;

    background: url('../../../../assets/images/icons/#{$filename}') center
      no-repeat;

    @if ($rightSide) {
      right: $padding;
    } @else {
      left: $padding;
    }
  }
}

@mixin input-container-style {
  position: relative;

  &--search-icon {
    @include input-container-with-icon('search.svg', $left-icon-padding, false);
  }
  &--required-icon {
    @include input-container-with-icon(
      'star-red.svg',
      $right-icon-padding,
      true
    );

    &:after {
      background-size: 7px 7px;
      transform: rotateZ(12deg);
    }
  }
  &--valid-icon {
    @include input-container-with-icon(
      'checkmark-green.svg',
      $right-icon-padding,
      true
    );

    &:after {
      transform: none;
    }
  }
  &--error-icon {
    @include input-container-with-icon(
      'error-x.svg',
      $right-icon-padding,
      true
    );

    &:after {
      transform: none;
    }
  }
}
