@import 'src/resources';

.ogp-home__pledge {
  padding-bottom: 35px;
}

.ogp-home__container {
  &--search {
    @include widthColumnLeft;

    padding: $content-padding-vertical $page-margin-horizontal;
    padding-bottom: $footer-height + $content-padding-vertical;

    @include desktop-small {
      padding: $content-padding-vertical $page-margin-horizontal-mobile;
      padding-bottom: $footer-height + $content-padding-vertical;
    }
  }

  &--cart {
    @include widthColumnRight;

    padding: 0;
  }
}
