@import 'src/resources';

.ogp-textarea {
  position: relative;
  margin-bottom: 30px;
}

.ogp-text-input {
  margin: 0.25em 0;
  position: relative;
}

.ogp-textarea__label {
  @include ut-label;
}

.ogp-textarea__field {
  @include input-field-style;

  height: auto;
  resize: none;
  padding: 15px 25px;

  @include placeholder {
    color: #8c8c8c;
  }

  &:focus {
    outline: none;
  }
}

.ogp-textarea__character-count {
  @include ut-h6;
  text-align: right;

  &--on-top {
    position: absolute;
    top: -20px;
    right: 0;
  }
}
