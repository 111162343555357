@import 'src/resources';

$link-padding-small: 10px;

.footer {
  @include page-layout-transitions;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $footer-height;
  margin: 0;
  z-index: $z-index-footer;
  background: $ut-white;

  @include mobile {
    display: none;
    height: auto;
  }

  &--small {
    display: none;
    width: 100%;

    position: relative;

    @include mobile {
      display: block;
    }
  }

  &-container {
    padding: 0 $page-margin-horizontal 0 $page-margin-horizontal;
    width: 100%;

    @include mobile {
      padding: 0;
    }

    @include desktop-small {
      padding: 0;
    }
  }

  &-section {
    width: 100%;
    height: 82px;
    padding: 10px 0;
    border-top: 2px solid $ut-limestone--t40;

    @include mobile {
      height: auto;
      padding: 0;
    }
  }

  &-links {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    position: relative;
    width: 80%;
    margin: auto;
    padding: 10px 0;

    @include mobile {
      flex-direction: column;
      text-align: center;
      padding-top: $link-padding-small;
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: $ut-burntorange;
      text-decoration: none;

      &:focus {
        outline: 1px dotted $ut-shade;
      }
    }
  }
  &-link {
    @include mobile {
      padding-bottom: $link-padding-small;
    }
  }

  &-copyright {
    font-size: 12px;
    font-style: italic;
    text-align: center;
    opacity: 0.6;
  }
}

.sidebar-open {
  right: $sidebar-width;
  @include page-layout-transitions;
}

.sidebar-open--slide {
  right: $sidebar-width;
  left: -320px;

  @include page-layout-transitions;
}
