@import 'src/resources';

$button-font-weight: 600;

@mixin transitions {
  transition: background-color, color, border-color, 0.33s ease-out;
}

@mixin focus-style {
  &:focus {
    outline: 1px dotted $ut-shade;
    outline-offset: 2px;
  }
}

@mixin button-base-style {
  @include button-unstyled;
  @include font-franklin;
  @include transitions;
  @include no-wrap;
  @include focus-style;
  @include ut-body;

  background-color: $ut-burntorange;
  border: 2px solid $ut-burntorange;
  border-radius: 4px;
  color: $ut-white;
  display: inline-block;
  font-weight: $button-font-weight;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  overflow: visible;

  margin-bottom: 0.55rem;
  padding: 0.5rem 1.5rem;

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus,
  &:hover {
    border-color: $ut-burntorange--s20;
    background-color: $ut-burntorange--s20;
    color: $ut-white !important;
  }

  &.active,
  &:active {
    border-color: $ut-burntorange--s20;
    background-color: $ut-burntorange--s20;
    color: $ut-white !important;
  }
}

@mixin button-secondary-style {
  @include button-base-style;

  color: $ut-burntorange;
  background-color: $ut-white;
  border-color: $ut-burntorange;
  cursor: pointer;

  &:hover {
    border-color: $ut-burntorange;
    background-color: $ut-burntorange;
    color: $ut-white !important;
  }

  &.active,
  &:active {
    background-color: $ut-burntorange--s20;
    border-color: $ut-burntorange--s20;
  }
}

@mixin button-small-style {
  font-size: 0.825rem;
  line-height: 1.5;
  padding: 0.25rem 1.75rem;
  min-height: 30px;

  @include mobile {
    padding: 0.25rem 0.75rem;
  }
}

@mixin button-large-style {
  @include button-base-style;
  @include ut-h3;

  font-weight: 700;

  line-height: 2.5rem;
}

.ut-btn {
  @include button-base-style;
}

.ut-btn--large {
  @include button-large-style;
}

.ut-btn--secondary {
  @include button-secondary-style;
}

.ut-btn--checkmark {
  @include button-small-style;
  @include transitions;
  @include focus-style;

  padding: 0;
  width: 32px;
  height: 32px;
  position: relative;
  background: $ut-white;
  border: 2px solid $ut-burntorange;
  font-size: 0;
  color: transparent;
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: 13px 10px;
    background: url('../../../../assets/images/icons/checkmark-orange.svg')
      center no-repeat;
  }
}

.btn--disabled {
  color: $ut-white;
  background-color: $ut-shade--s20;
  border-color: $ut-shade--s20;
  pointer-events: none;

  &:focus,
  &:hover {
    color: $ut-charcoal--s40 !important;
    background-color: $ut-shade--s20;
    border-color: $ut-shade--s20;
  }
}

.btn--unstyled {
  @include button-unstyled;
}

.btn--small {
  @include button-small-style;
}

.corner--pill {
  border-radius: 2rem !important;
}

.corner--square {
  border-radius: 0 !important;
}

.ut-btn--circle {
  @include button-unstyled;
  @include focus-style;

  content: ' ';

  width: 60px;
  height: 60px;

  border-radius: 50%;
  background: $ut-limestone--t40;
  cursor: pointer;
  flex-shrink: 0;

  &:after {
    content: ' ';
    width: 100%;
    height: 100%;
    background: url('../../../../assets/images/icons/add-orange.svg') center
      no-repeat;
    display: block;
    overflow: visible;
  }

  &.active {
    background: $ut-burntorange;
    &:after {
      background: url('../../../../assets/images/icons/remove-white.svg') center
        no-repeat;
    }
  }
}
