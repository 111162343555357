@import 'src/resources';

$border: 1px solid $ut-charcoal--t20;

.modal {
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.modal__header {
  @include ut-h3;

  font-size: 1.5rem;
  font-weight: 600;
  align-self: flex-start;
  padding: $modal-padding;
}

.modal__content {
  width: 100%;
  border-bottom: $border;
  border-top: $border;
  padding: $modal-padding;
}

.modal__window {
  background: $ut-white;
  overflow: auto;
  padding: 0;
  border-radius: 4px;

  &:focus,
  &:active {
    outline: none;
  }
}

.modal__button-container {
  align-self: flex-end;
  padding: 10px $modal-padding;
}

.modal__button {
  margin: 0;
}
