@import 'src/resources';

$divider-border: 1px solid $ut-limestone--t40;
$primary-padding-top: 40px;

@mixin label-small {
  @include font-franklin;
  font-size: 0.875rem;
}

.pledge-summary {
  position: relative;
}

.pledge-summary__content {
  background: $ut-limestone--t20;
  padding: 120px 50px;
  padding-right: ($page-margin-horizontal);

  @include desktop-small {
    padding-right: 50px;
  }
}

.pledge-summary__header {
  padding-bottom: 28px;
  border-bottom: $divider-border;
}

.pledge-summary__footer {
  @include label-small;
  padding-top: 14px;
}

.pledge-summary__empty {
  padding-top: $primary-padding-top;
}

.pledge-summary__items--primary {
  @include ut-h5;

  padding-top: $primary-padding-top;
}

.pledge-summary__items--secondary {
  padding-top: 54px;
  padding-bottom: 24px;
  border-bottom: $divider-border;
}

.pledge-summary__item {
  display: flex;
  justify-content: space-between;

  padding: 6px 0px;

  &--secondary {
    @include label-small;

    .pledge-summary__item-value {
      font-weight: 600;
    }
  }

  &-title {
    text-transform: capitalize;
  }

  &--final {
    padding: 28px 0px;

    .pledge-summary__item-value {
      font-weight: 600;
    }
  }
}

.pledge-summary__item-value {
  padding-left: 30px;
}
