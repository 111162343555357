@import 'src/resources';

.sidebar_container {
  position: fixed;
  right: 0;
  top: 0;
  width: $sidebar-width;
  height: 100vh;
  background: $ut-charcoal;
  &-open {
    @include page-layout-transitions;
    display: flex;
    flex-flow: column;
  }
  &-closed {
    @include page-layout-transitions;
    right: calc(-1 * #{$sidebar-width});
  }
}

.sidebar_search {
  position: relative;
  height: 170px;
  width: 100%;
  padding: 40px;
  background: #2d3940;

  &-form {
    position: relative;
    height: 35px;
    width: 100%;
    max-width: 100%;
    padding: 5px;
    margin: 15px 0 0;
    color: $ut-white;
    border-color: $ut-burntorange;
    border-radius: 5px;
    background: rgba($ut-white, 0.2);
  }

  &-field {
    position: absolute;
    top: 0;
    height: 100%;
    left: 5px;
    right: 30px;
    width: 85%;
    border: none;
    outline: none;
    background: transparent;
    caret-color: #f9f9f9;
    color: #f9f9f9;
    font-size: 14px;
    &::placeholder {
      color: #f9f9f9;
    }
  }

  &-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 35px;
    margin: 0;
    border: none;
    background: none;
    color: $ut-white;
    cursor: pointer;

    &:before {
      @include sidebar-search;
      position: absolute;
      top: 8px;
      left: 7px;
      color: #f9f9f9;
    }
  }
}

.sidebar_links {
  @include hover-transition(0.2s);
  display: block;
  padding: 28px 40px;
  margin-top: -1px;
  list-style: none;
  text-decoration: none;
  font-size: 16px;
  color: $ut-white;
  a,
  div {
    color: $ut-white;
    &:focus {
      outline: none;
    }
  }
  &-link {
    @include hover-transition(0.2s);
    text-decoration: none;
    height: 41px;
    padding: 10px 0;
    margin-top: -1px;
    margin-bottom: 1px;
    outline: none;
    font-size: 16px;
    color: $ut-white;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}

.give-now-button {
  line-height: 1.5em;
  padding: 0.5em 3.3em 0.5em 3.3em;
  margin: 27px 0;
  border-radius: 2px;
  text-align: center;
  background-color: $ut-burntorange;
  font-size: 14px;
  transition: hover-transition(0.2s);
  cursor: pointer;
  font-weight: 600;
  a {
    @include hover-transition(0.2s);
    display: block;
    height: 41px;
    padding: 10px 0;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $ut-white;
  }
}
