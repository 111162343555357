$transition-time: 0.15s;

$transition-position: 0.8s cubic-bezier(0.77, 0, 0.175, 1);

@mixin page-layout-transitions {
  transition: right $transition-position, left $transition-position,
    background 0.25s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1), transform 0.4s ease-in-out,
    -webkit-transform 0.4s ease-in-out;
}

@mixin hover-transition($time) {
  transition: all $time ease-in-out;
}
