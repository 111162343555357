@import 'src/resources';

$border-divider: 1px solid $ut-shade--t40;

.cart {
  height: 100%;
  padding: 72px 44px;
  background: $ut-limestone--t20;

  @include desktop-small {
    padding: 72px 10px 72px 20px;
    margin-left: 10px;
  }

  &--empty {
    display: none;
  }

  &__header {
    padding-bottom: 28.5px;
    border-bottom: $border-divider;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item-count {
    background: $ut-burntorange;
    color: $ut-white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
  }

  &__checkout-button {
    width: 211px;
  }

  &__frequency-title {
    @include ut-body--medium;
  }

  &__frequency {
    padding: 33px 0;
    border-bottom: $border-divider;
  }

  &__frequency-options {
    padding-top: 26px;
  }

  &__frequency-recurring {
    transition: all 200ms ease-in-out;
    height: auto;
    opacity: 1;
    visibility: visible;

    &--hidden {
      height: 0px;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__footer {
    padding: 33px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__total {
    margin-left: 40px;

    &__container {
      text-align: right;
      padding-top: 25px;
    }
  }

  &__clear {
    margin-right: 50px;
  }

  &__empty {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 100px;
  }
}

.cart__header {
  padding-bottom: 28.5px;
  border-bottom: $border-divider;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart__item-count {
  background: $ut-burntorange;
  color: $ut-white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
}

.cart__checkout-button {
  width: 211px;
}

.cart__frequency-title {
  @include ut-body--medium;
}

.cart__frequency {
  padding: 33px 0;
  border-bottom: $border-divider;
}

.cart__frequency-options {
  padding-top: 26px;
}

.cart__footer {
  padding: 33px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cart__total__container {
  text-align: right;
  padding-top: 25px;
}

.cart__total {
  margin-left: 40px;
}

.cart__clear {
  margin-right: 50px;
}

.cart__empty {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 100px;
}
