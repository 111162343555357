@import 'src/resources';

.confirmation-page {
  height: 100%;
}

.confirmation-page__confirmation-container {
  padding-top: 48px;
  padding-bottom: $footer-height;

  @include mobile {
    width: 100%;
    padding: 50px 30px;
  }
}

.confirmation__transaction-info-value {
  display: inline;
}

.confirmation-page__gif-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 350px;
}
