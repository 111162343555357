@import 'src/resources';

.embed-view {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    background: $ut-limestone--t20;
    padding: 35px 30px;
    border-radius: 20px;
  }

  &__page-frame {
    display: grid;
  }

  &__all-funds {
    @include ut-link;

    padding-top: 15px;
    text-decoration: underline;
    cursor: pointer;
    color: $ut-burntorange;
  }

  .btn--disabled {
    $disabled-color: $ut-charcoal--t20;
    background-color: $disabled-color;
    border-color: $disabled-color;
    color: $ut-charcoal;
  }
}
