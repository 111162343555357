@import 'src/resources';

.donor-fields__title {
  @include ut-h3;
  font-weight: 600;

  display: flex;
}

.donor-fields__subtitle {
  @include ut-h4;

  font-weight: 600;
}

.donor-fields__divider {
  position: relative;
  height: 1px;
  opacity: 0.2;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $ut-charcoal;
  }
}

$scale: 0.6;
@mixin pb-scaled-mobile($initial) {
  padding-bottom: $initial;
  @include mobile {
    padding-bottom: $initial * $scale;
  }
}

.donor-fields__line-break {
  @include mobile {
    display: none;
  }
}

.donor-fields__padding--bottom {
  &-1 {
    @include pb-scaled-mobile(10px);
  }
  &-2 {
    @include pb-scaled-mobile(20px);
  }
  &-3 {
    @include pb-scaled-mobile(30px);
  }
  &-4 {
    @include pb-scaled-mobile(40px);
  }
}
