@import 'src/resources';

.confirmation__thanks {
}

.confirmation__transaction-info {
  padding: 40px 0;
}

.confirmation__transaction-info-value {
}

.confirmation__receipt {
  padding: 40px 0;
  font-style: italic;
  color: $ut-charcoal;
  opacity: 0.75;
}

.confirmation__survey-container {
  border: 1px solid $ut-shade--t40;
  padding: 19px 39px;
}

.confirmation__pb--1 {
  padding-bottom: 1rem;
}

.confirmation__pb--2 {
  padding-bottom: 2rem;
}
