@import 'src/resources';

.mob-checkout-page {
  &__form-container {
    padding: 30px $page-margin-horizontal-mobile;
    width: 100%;
    overflow: hidden;
  }

  &__payment-button-container {
    min-width: 225px;
  }
}
