@import 'src/resources';

.embed-giving-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 13px 0;
  border-bottom: 1px solid $ut-limestone--t40;

  &__info {
    padding-right: 20px;
  }

  &__name {
    @include ut-h5;
  }

  &__description {
    @include ut-body;
    color: $ut-shade--s40;
  }
}
