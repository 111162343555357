@import 'src/resources';

.mini-embed {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__amount-buttons {
    display: flex;
    justify-content: space-between;
  }

  &__amount-button {
    @include font-franklin;

    font-weight: 600;
    font-size: 13px;

    flex-basis: 0;
    flex-grow: 1;

    color: $ut-white;
    cursor: pointer;

    background: transparent;
    height: 38px;

    border: 2px solid $ut-white;
    border-radius: 20px;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &.active {
      background-color: $ut-burntorange--s20;
      border-color: $ut-burntorange--s20;
      color: white;
    }
  }

  &__make-gift {
    @include button-unstyled;
    @include font-franklin;

    width: 100%;

    padding: 19px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: 17px;
    margin: 10px 0 10px;
    text-align: center;
    height: 42px;

    flex-basis: 0;
    flex-grow: 1;

    color: white;
    cursor: pointer;

    background: $ut-white;
    color: $ut-burntorange;

    border: none;
    border-radius: 20px;

    pointer-events: none;

    &.active {
      background: $ut-burntorange;
      color: $ut-white;
      pointer-events: all;
    }
  }

  &__custom-input-container {
    margin-top: 10px;

    position: relative;
    overflow: hidden;
    transition: height 0.5s;

    &--closed {
      height: 0;
    }
  }

  &__custom-input {
    height: 42px;
    border-radius: 20px;
    text-align: center;

    width: 100%;
    font-weight: 600;
    -moz-appearance: textfield;
    background: #f3f4f4;
    outline: none;
    border: none;

    @include placeholder {
      color: black;
      transform: translateX(-100px);
    }
  }

  &__custom-input-error {
    color: $ut-error;
    height: 24px;

    font-size: 0.75rem;
    font-weight: 600;
  }

  &__custom-input-clear {
    @include button-unstyled;

    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 22px;
    top: 21px;
    transform: translateY(-50%);
    background: url('../../../../assets/images/icons/x.svg') center no-repeat;
  }

  &__custom-gift {
    @include button-unstyled;
    @include font-franklin;

    padding-left: 10px;
    font-weight: 600;
    font-size: 13px;
    text-decoration: underline;
    position: relative;
    overflow: visible;

    flex-basis: 0;
    flex-grow: 1;

    color: $ut-white;

    &:after {
      content: ' ';
      display: block;
      width: 10px;
      height: 10px;
      background: url('../../../../assets/images/icons/arrow-right-white.svg')
        center no-repeat;

      position: absolute;
      top: 50%;
      right: -15px;

      transform: translateY(-50%);
    }
  }
}

.mini-embed--dark {
  .mini-embed__amount-button {
    border-color: $ut-burntorange;
    color: $ut-burntorange;

    &.active {
      border-color: $ut-burntorange--s20;
      color: $ut-white;
    }
  }

  .mini-embed__make-gift {
    background: transparent;
    border: 2px solid $ut-burntorange;
    color: $ut-burntorange;

    &.active {
      background: $ut-burntorange;
      color: $ut-white;
    }
  }

  .mini-embed__custom-gift {
    color: $ut-burntorange;

    &:after {
      background: url('../../../../assets/images/icons/arrow-right-orange.svg')
        center no-repeat;
    }
  }
}
