@import './_colors.scss';

$icon-width: 20px;
$left-icon-padding: 15px;
$right-icon-padding: 20px;

@mixin hide-input-number-arrows {
  /* Making the stepper disappear on number fields */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

@mixin input-with-icon($icon-padding, $rightSide) {
  $field-padding: $icon-width + ($icon-padding * 2);
  @if ($rightSide) {
    margin-right: $field-padding;
  } @else {
    padding-left: $field-padding;
  }
}

@mixin input-field-style {
  @include hide-input-number-arrows;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-color: $ut-white;
  background-image: none;
  background-clip: padding-box;
  color: $ut-charcoal--s40;
  border: 1px solid $ut-charcoal--t20;
  border-radius: 8px;
  display: block;
  font-size: 1rem;

  padding: 0 1rem;
  line-height: 1.5625rem;
  height: 38px;
  width: 100%;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &--error {
    border-color: $ut-error;
  }

  &--missing {
    border: 3px solid $ut-red;
    background-color: #bd182333;
  }

  &:active,
  &:focus,
  &--open {
    border-color: $ut-shade--s40;
  }

  &--valid-icon {
    @include input-with-icon($right-icon-padding, true);
  }

  &--required-icon {
    @include input-with-icon($right-icon-padding, true);
  }

  &--error-icon {
    @include input-with-icon($right-icon-padding, true);
  }

  &--search-icon {
    @include input-with-icon($left-icon-padding, false);
  }

  @include placeholder {
    @include font-franklin;

    color: $ut-charcoal;
  }
}
