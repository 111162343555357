@import 'src/resources';

.gift-builder {
  padding: 42.5px 0px 25px;
  border-bottom: 1px solid $ut-shade--t40;

  @include mobile {
    padding: 18px 15px 21.5px;
    margin-bottom: 20px;

    background-color: $ut-limestone--t20;
    border-radius: 12px;
  }
}

.gift-builder__name {
  font-weight: 700;
}

.gift-builder__amount-value {
  font-weight: 600;
}

.gift-builder__header {
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  align-items: center;

  @include mobile {
    @include ut-label;
    align-items: flex-start;

    margin-bottom: 0;
  }
}

.gift-builder__right-container {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-end;

    margin-left: 5px;
  }
}

.gift-builder__amount-title {
  @include ut-body--medium;

  @include mobile {
    @include ut-label;
  }
}

.gift-builder__amounts {
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mobile {
    padding: 17px 0;
  }
}

.gift-builder__amount {
  flex-grow: 1;
  // `flex-basis` should be desired resultant size minus horizontal margins
  flex-basis: calc(25% - 10px);
  margin: 5px;
  overflow: visible;
  padding: 0.5rem 1rem;
}

.gift-builder__delete {
  @include button-unstyled;

  &:focus {
    outline: 1px dotted $ut-shade;
    outline-offset: 4px;
  }

  width: 10px;
  height: 10px;
  background: url('../../../../../assets/images/icons/x.svg') center no-repeat;
  margin-left: 10px;

  @include mobile {
    @include ut-h6;

    background: initial;
    width: initial;
    height: initial;

    &::after {
      content: 'Remove';
    }

    font-weight: 400;

    color: rgba($ut-charcoal, 0.75);

    text-decoration: underline;
  }
}
