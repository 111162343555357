@import 'src/resources';

$button-margin: 10px;
$button-break-width: 500px;

.embed-amount-page {
  grid-row: 1;
  grid-column: 1;

  &--hidden {
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    height: 0;

    &.embed-amount-page--fixed-height {
      height: auto;
    }
  }

  &__amount-section {
    width: 100%;
    max-width: 430px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  &__amounts {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    flex-wrap: wrap;
  }

  &__amount-title {
    @include embed-title;

    padding: 22px 0;
    text-align: center;
  }

  &__amount-btn {
    font-size: 0.8125rem;
    height: 38px;
    width: 85px;
    padding: 0;
    margin: $button-margin;
    text-align: center;
  }

  /* Appears on <500px screens to make 
     the buttons to break evenly to two lines. 
     Without it, the last button would break into an 
     awkward T shape on >500px viewports. */
  &__button-break {
    @mixin button-break-small {
      @media (max-width: #{$button-break-width}) {
        @content;
      }
    }

    flex-basis: 100%;
    height: 0;
    display: none;

    @include button-break-small {
      display: block;
    }
  }

  &__custom-amount {
    width: 100%;
  }

  &__frequency-title {
    @include embed-title;
    position: relative;

    padding: 22px 0;
    text-align: center;
  }

  &__frequency-tooltip {
    position: absolute;
    bottom: 23px;
    right: -15px;
  }

  &__frequency-radio {
    justify-content: center;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
  }

  &__bottom-button {
    flex-grow: 1;
    flex-basis: 0;
    border-width: 3px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
