@import 'src/resources';

.sidebar_links {
  &-dropdown {
    outline: 0;
    list-style: none;
    line-height: 1.5em;
    font-size: 100%;

    &__icon {
      position: absolute;
      right: 0;
      display: inline-block;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
      &:before {
        @include accordion-arrow;
        @include hover-transition(0.4s);
        display: block;
        color: $ut-white;
        opacity: 0.6;
        transform: rotate(180deg);
      }
      &.dropdown-closed:before {
        @include hover-transition(0.4s);
        transform: none;
      }
    }

    &__title {
      @include hover-transition(0.2s);
      position: relative;
      height: 41px;
      padding: 10px 0;
      margin: 0;
      border: none;
      outline: 0;
      font-weight: 700;
      font-size: 16px;
      color: $ut-white;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
      &:focus {
        outline: 1px dotted $ut-white;
      }
    }
  }

  &-children {
    @include hover-transition(0.7s);
    position: relative;
    height: auto;
    max-height: 1000px;
    list-style: none;
    line-height: 1.2em;
    opacity: 1;
    &.dropdown-closed {
      @include hover-transition(0.7s);
      visibility: hidden;
      opacity: 0;
      max-height: 0;
    }
  }

  &-child {
    height: 36px;
    padding-left: 5%;
    outline: 0;
    list-style: none;
    line-height: 1.2em;
    opacity: 0.6;
    &__link {
      @include hover-transition(0.2s);
      display: block;
      height: 41px;
      padding: 10px 0;
      border: none;
      outline: 0;
      list-style: none;
      line-height: 1.2em;
      font-size: 16px;
      color: $ut-white;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &-external {
    &:after {
      @include external-link;
      position: absolute;
      margin-left: -20px;
    }
  }
}
