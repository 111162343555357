@import 'src/resources';

.info-tooltip {
  padding: 0px 20px;
  color: $ut-white;
}

.info-tooltip__button {
  @include button-unstyled;
  @include ut-h6;

  width: 24px;
  height: 24px;
  background: $ut-limestone--t40;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $ut-burntorange;
  border-radius: 50%;

  &:focus {
    outline: 1px dotted $ut-shade;
    outline-offset: 4px;
  }

  &--large {
    @include mobile {
      display: none;
    }
  }

  &--mobile {
    display: none;

    @include mobile {
      display: flex;
    }
  }
}

.info-tooltip__tooltip {
  max-width: 180px;
  background-color: $ut-charcoal !important;
  text-align: center;

  &:after {
    border-top-color: $ut-charcoal !important;
  }
}
