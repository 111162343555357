@import 'src/resources';

$gutter-horizontal: 230px;
$gutter-vertical: 40px;

$gutter-horizontal-mobile: 20px;

.preview-notification {
  &__preview-container {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-grow: 1;

    img {
      max-height: 60vh;
    }
  }

  &__window {
    position: absolute;

    top: $gutter-vertical;
    bottom: $gutter-vertical;
    left: $gutter-horizontal;
    right: $gutter-horizontal;

    margin: 0;
    transform: none;

    @include mobile {
      left: $gutter-horizontal-mobile;
      right: $gutter-horizontal-mobile;
      bottom: auto;
    }
  }
}
