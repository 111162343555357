@import 'src/resources';

.embed-items-page {
  grid-row: 1;
  grid-column: 1;

  &--hidden {
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  }

  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 25px 0 10px;
  }

  &__next {
    width: 200px;
    margin: auto;
  }
}
