@import 'src/resources';

.tooltip-modal {
  background-color: $ut-charcoal;
  padding: 20px;
  color: $ut-white;

  &__window {
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100% - 2rem);
    bottom: auto;
    right: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    &:focus {
      outline: none;
    }
  }

  &__close {
    @include button-unstyled;

    width: 100%;
    text-align: center;
    color: $ut-white;
    margin-top: 20px;
    display: block;
    background: transparent;
    border-color: $ut-white;
    border: 2px solid $ut-white;
    height: 40px;

    &:focus,
    &:hover {
      outline: none;
      color: $ut-charcoal;
      background: $ut-white;
    }
  }
}
