@import 'src/resources';

.pledge-payment-amount-btn {
  @include button-unstyled;

  border: 3px solid $ut-limestone--t40;
  border-radius: 12px;
  padding: 20px 12px;
  text-align: center;
  cursor: pointer;

  @include mobile {
    width: 100%;
  }

  @mixin amount-font {
    @include ut-h3;

    @include mobile {
      @include ut-h2;
      font-weight: 800;
    }
  }

  &--selected {
    border-color: $ut-burntorange;
  }

  &--overdue {
    border-color: $ut-error;
  }

  &__label {
    color: $ut-charcoal;

    @include mobile {
      @include ut-h4;
    }
  }

  &__amount {
    @include amount-font;

    min-width: 150px;
    display: flex;
    justify-content: center;

    &--selected {
      color: $ut-burntorange;
    }

    &--overdue {
      color: $ut-error;
    }
  }

  &__input-container {
    display: flex;
    justify-content: center;
    max-height: 41px;
  }

  &__dollar-sign {
    @include amount-font;
    width: 25px;
  }

  &__input {
    @include amount-font;
    @include hide-input-number-arrows;

    color: $ut-burntorange;
    width: 130px;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    margin-top: 3px;
    box-sizing: content-box;
    border-bottom: 2px solid $ut-charcoal;
    flex-grow: 1;

    transition: width 300ms, flex-grow 300ms;

    &--empty {
      @extend .pledge-payment-amount-btn__input;

      width: 0px;
      flex-grow: 0;
    }
  }
}
