@import 'src/resources';

.pledge-payment-no-id {
  @include mobile {
    padding-bottom: 40px;
  }

  &__custom-amount {
    @include mobile {
      width: 100%;
    }
  }

  &__submit-button {
    margin: 2px 0 0 30px;
    min-width: 130px;

    @include mobile {
      display: none;
      margin: 0;
    }

    &--mobile {
      display: none;
      width: auto;
      min-width: 300px;

      margin: auto;

      @include mobile {
        display: block;
      }
    }
  }
}
