@import 'src/resources';

.ogp-radio {
  @include list-unstyled;
  display: flex;
  flex-wrap: wrap;

  &--style-buttons,
  &--single-line {
    flex-wrap: nowrap;
    align-items: baseline;
  }
}

.ogp-radio__option {
  margin-right: 20px;

  &:focus-within {
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  @include mobile {
    margin-right: 15px;
  }

  &--button-container {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.ogp-radio__input {
  position: absolute;
  left: -999em;
}

@mixin radio-style($color) {
  @include ut-body;
  cursor: pointer;
  margin-bottom: 1rem;

  @include checkbox-label-style(false, $color);

  &.ogp-radio__label--selected {
    @include checkbox-label-style(true, $color);
  }
}

.ogp-radio__label {
  @include radio-style($ut-bluebonnet);

  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &--hide {
    display: none;
  }
}

.ogp-radio__label--brand-color {
  @include radio-style($ut-burntorange);
}

.ogp-radio__label--desktop-only {
  @include ut-body;
}

.ogp-radio__button {
  width: 100%;
  text-align: center;
}
