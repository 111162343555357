@import 'src/resources';

.ogp-modal__overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-modal;
  background: rgba($ut-charcoal, 0.75);

  &--payment-open {
    display: none;
  }
}
