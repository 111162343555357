@import 'src/resources';

.missing-fields {
  border: 3px solid $ut-red;
  border-radius: 8px;
  background-color: #bd182333;

  padding: 15px;
  margin-bottom: 25px;

  @include hover-transition(0.2s);

  &--hidden {
    opacity: 0;
    height: 0;

    border: 0px;
    background-color: transparent;
    display: none;
  }
}
