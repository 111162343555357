@import 'src/resources';

.ogp-tab-bar {
  @include font-franklin;
  width: 100%;
  position: relative;

  background: $ut-white;
  color: $ut-charcoal;

  &:after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 1px;
    background: $ut-limestone--t40;
    z-index: 0;
    z-index: 0;
    bottom: 0;

    &--search-mobile {
      content: none;
    }
  }

  &--search-mobile {
    justify-content: flex-start;
    padding: 0 $page-margin-horizontal-mobile;

    overflow: scroll;
    background: transparent;

    &:after {
      content: none;
    }
  }

  &--hidden {
    height: 0;
    overflow: hidden;
    pointer-events: none;
  }
}

.ogp-tab-bar__content {
  width: 100%;
  height: 100%;

  display: flex;
  position: relative;
  justify-content: space-between;

  &--narrow {
    max-width: 250px;
  }
}

.ogp-tab-bar__selected-border {
  content: ' ';
  position: absolute;
  height: 3px;
  background: $ut-burntorange;
  z-index: 1;
  bottom: -1px;
  transition: all $transition-time;
}

.ogp-tab-bar__item {
  @include button-unstyled;
  transition: all $transition-time;

  border-bottom: 2px solid transparent;
  z-index: 1;
  flex-grow: 1;
  flex-basis: 0;

  padding: 8px;
  cursor: pointer;

  color: $ut-charcoal;
  text-align: center;

  @include mobile {
    color: $ut-shade--s20;
    white-space: nowrap;

    &--selected {
      color: $ut-charcoal;
    }
  }

  &:focus {
    outline: 1px dotted $ut-shade;
  }

  &--search-mobile {
    @include mobile-header-bold;

    padding: 12px 17px;
    flex-grow: 0;

    background: $ut-limestone--t40;
    color: $ut-bluebonnet;
    border: none;
    border-radius: 16px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &--selected {
    font-weight: 700;

    &.ogp-tab-bar__item--search-mobile {
      background: $ut-bluebonnet;
      color: white;
    }
  }

  &--highlighted {
    color: $ut-burntorange;
  }

  &--disabled {
    pointer-events: none;
  }
}
