@import 'src/resources';

.gift-summary {
  width: 100%;
  padding: 120px 50px;
  background: $ut-limestone--t20;
  padding-right: ($page-margin-horizontal);
}

.gift-summary__container {
  position: relative;
}

.gift-summary__header {
  padding-bottom: 28.5px;
  border-bottom: 1px solid $ut-shade--t40;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gift-summary__title {
  @include ut-h4;
  font-weight: 600;
}

.gift-summary__item-count {
  background: $ut-burntorange;
  color: $ut-white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
}

.gift-summary__item-name {
  font-weight: 700;
}

.gift-summary__item-amount {
  font-weight: 600;
}

.gift-summary__gifts {
  padding: 40px 0;
}

.gift-summary__frequency {
  padding-bottom: 17.5px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $ut-shade--t40;
  font-size: 0.875rem;

  &__item {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    padding-bottom: 5px;
  }
}

.gift-summary__frequency-value {
  font-weight: 600;
}

.gift-summary__total {
  padding: 28px 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gift-summary__comments {
  font-weight: 600;
}

.automatic-payment-acknowledgement {
  margin: 30px 0;
  .acknowledgement {
    font-size: 0.75rem;
  }
}
