@import 'src/resources';

.switch-gift {
  color: $ut-charcoal;
}

.switch-gift__link {
  @include ut-link;
  @include ut-body;

  color: $ut-burntorange;
  cursor: pointer;
}
