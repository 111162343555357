@import 'src/resources';

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.flex {
  display: flex;

  > div {
    padding: 5px;
  }
}

.not-found-error {
  color: #bf5700;
  border-color: #bf5700;
  border-left: 4px solid;
  padding: 1rem;
  background-color: rgba(255, 250, 240, 1);
}

.spinner-container {
  display: flex;
  padding: 1rem;
  color: #bf5700;
  border-color: #bf5700;
  border-left: 4px solid;
  background-color: rgba(255, 250, 240, 1);
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 5px solid rgba(255, 250, 240, 1);
  border-top: 5px solid #bf5700;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin-right: 1rem;
  margin-top: -0.1rem;
}

.pledge-payment-id {
  &__container {
    display: flex;

    padding-bottom: 40px;

    @include mobile {
      flex-direction: column;
    }
  }

  &__id-button {
    margin-top: auto;
    margin-left: 16px;

    @include mobile {
      display: none;
    }

    &--large {
      display: none;

      align-items: center;

      @include mobile {
        width: 100%;
        max-width: 300px;

        margin: 0 auto;

        display: block;
      }
    }
  }

  &__amount-buttons {
    display: flex;
    justify-content: flex-start;
    overflow: visible;

    &:focus {
      outline: none;
    }

    @mixin amount-buttons-vertical {
      flex-direction: column;
      align-items: center;
    }

    @include desktop-small {
      @include amount-buttons-vertical;
    }

    @include mobile {
      @include amount-buttons-vertical;
    }
  }

  &__payment-cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: visible;

    &:focus {
      outline: none;
    }

    @mixin amount-buttons-vertical {
      flex-direction: column;
      align-items: center;
    }

    @include desktop-small {
      @include amount-buttons-vertical;
    }

    @include mobile {
      @include amount-buttons-vertical;
    }
  }

  &__amount-button {
    flex: 1 0 auto;

    &:focus {
      outline: none;
    }

    @mixin small-style {
      width: 100% !important;
      max-width: 430px;
    }

    @include mobile {
      @include small-style;
    }

    @include desktop-small {
      @include small-style;
    }

    &:not(:last-child) {
      margin-right: 35px;

      @mixin small-style {
        margin: 0 0 12px;
      }

      @include desktop-small {
        @include small-style;
      }

      @include mobile {
        @include small-style;
      }
    }
  }

  &__autopay-section {
    display: flex;
    padding-top: 30px;
    padding-bottom: 20px;

    @include mobile {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__autopay-empty {
    content: ' ';
    height: 50px;
  }

  &__submit-button {
    width: 200px;
    align-self: center;
    margin-bottom: 40px;

    @include mobile {
      display: none;
    }

    &--mobile {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 300px;
      display: none;

      @include mobile {
        display: block;
      }
    }
  }

  &__recommended-badge {
    @include ut-label;

    font-weight: 700;
    background: $ut-cactus;
    border-radius: 8px;
    padding: 5px;
    margin: 2px 0 auto 30px;

    @include mobile {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  &__id-input {
    width: 230px;

    @include mobile {
      width: 100%;

      padding-bottom: 40px;
    }
  }
}

.card {
  border: 3px solid #f2f1ed;
  border-radius: 12px;
  margin: 0 0 12px;
  width: 100%;
  // max-width: 430px;

  .title {
    font-weight: 700;
    font-size: 1.2em;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.7);
  }

  .subtitle {
    font-weight: 400;
    font-size: 0.8em;
    font-style: italic;
    color: rgba(0, 0, 0, 0.6);
  }

  .card-container {
    width: 100%;

    .card-header {
      background-color: #f2f1ed;
      padding: 1em 1em;
    }

    .line-item-wrapper {
      .line-item {
        padding: 0.8em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fbfbf9;

        &:not(:last-child) {
          border-bottom: 1px solid #f2f1ed;
        }
      }

      .due-date {
        flex: 0 0 35%;
        font-weight: 500;
      }

      .amount {
        font-weight: 500;
      }

      .add-to-cart {
        transform: scale(0.7);
      }

      .ut-btn {
        margin-bottom: 0;
        min-width: 133px;

        &.selected {
          color: #bf5700 !important;
          background-color: white;
        }
      }
    }
  }
}

.bottom-spacing {
  margin-bottom: 1em;
}

.nothing-due {
  padding: 1em;
  font-size: 700;
}

// .card:hover {
//   box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
// }

/* Add some padding inside the card container */
