@import './fonts.scss';

@mixin accordion-arrow {
  content: '3';
  font-family: ElegantIcons;
  font-size: 21px;
  font-weight: 400;
}

@mixin external-link {
  content: '\f35d';
  font-family: FontAwesome;
  font-size: 12px;
  font-weight: 900;
}

@mixin sidebar-hamburger {
  content: '\61';
  font-family: ElegantIcons;
  font-size: 32px;
}

@mixin sidebar-close {
  content: '\4d';
  font-family: ElegantIcons;
  font-size: 32px;
}

@mixin sidebar-search {
  content: '\55';
  font-family: ElegantIcons;
  font-size: 13px;
  font-weight: 400;
}
