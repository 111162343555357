@import 'src/resources';

.fixed-right__content {
  @include widthColumnRight;
  width: auto;
  position: relative;

  &--scrolled {
    //@include widthColumnRight;
    // position: fixed;
  }
}
