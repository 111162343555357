@import 'src/app/style/partials/resources/colors';

$container-padding: 20px;
$padding-above-button: 23px;
$amount-width: 50%;

@mixin mobile-summary {
  padding: $container-padding;
  padding-top: 0;

  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;

  background: $ut-limestone--t20;
  text-align: center;
  transition: transform 200ms ease-out, opacity 100ms ease-out;
  opacity: 1;
  pointer-events: inherit;

  &--hidden {
    opacity: 0;
    transform: translateY(100%);
    pointer-events: none;
  }

  &:after {
    content: ' ';

    height: 1px;
    position: absolute;
    top: 0;
    left: $container-padding;
    right: $container-padding;
    background-color: $ut-burntorange;
  }

  &__bottom {
    padding-top: 24px;
  }

  &__amount {
    @include ut-body--medium;
    padding-bottom: 5px;

    @include mobile {
      font-size: 1rem;
    }
  }

  &__secondary-text {
    @include mobile-header-bold;

    padding-bottom: $padding-above-button;
    color: $ut-bluebonnet;
  }

  &__expand-toggle {
    cursor: pointer;

    &:after {
      content: ' ';
      background: url('../../../../assets/images/icons/chevron-up-bluebonnet.svg')
        center no-repeat;
      display: inline-block;
      position: relative;
      bottom: 1px;
      width: 10px;
      height: 6px;
      margin-left: 5px;
    }

    &--expanded {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  }

  &__gifts {
    padding: 10px 0;
    border-bottom: 1px solid $ut-charcoal--t20;
  }

  &__gift {
    display: flex;
    padding: 10px 0;
    align-items: center;
    color: $ut-charcoal;
  }

  &__gift-amount {
    @include ut-label;

    text-align: left;
    width: $amount-width;
    flex-shrink: 0;
  }

  &__gift-info {
    width: 100% - $amount-width;
    text-align: right;
  }

  &__gift-name {
    @include ut-label;
    @include no-wrap;

    text-overflow: ellipsis;
    padding-bottom: 5px;
  }

  &__gift-frequency {
    @include ut-label;

    font-weight: 500;
    color: $ut-shade--s40;
  }

  &__bottom-button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__bottom-button {
    flex-grow: 1;
    flex-basis: 0;
    margin: 5px;
    // margin-top: 0;
    margin-bottom: auto;

    &--small {
      flex-grow: 0.4;
    }
  }
}
