@import 'src/resources';

.search-view,
.search-view--mobile {
  display: flex;
  flex-direction: column;
  min-height: 600px;

  &__show-more {
    align-self: center;
    margin: 30px 0;

    color: $ut-charcoal--s40 !important;
    background-color: $ut-limestone--t40;
    border-color: $ut-limestone--t40;

    &:focus,
    &:hover {
      color: $ut-charcoal--s40 !important;
      background-color: $ut-limestone--t40;
      border-color: $ut-limestone--t40;
    }
  }
}

.search-view__giving-items-container {
  position: relative;
}

.search-view--mobile {
  padding-top: 30px;
}

.search-view__tabbar {
  margin-bottom: 35px;

  &--mobile {
    @extend .search-view__tabbar;

    margin-bottom: 0;
  }
}

.search-view__item {
  display: flex;
  align-items: center;
  min-height: 125px;
  width: 100%;

  border-bottom: 1px solid $ut-limestone--t40;

  padding: 10px 0;

  &--mobile {
    display: flex;
    background: $ut-white;
    padding: 20px $page-margin-horizontal-mobile;
    flex-wrap: nowrap;
  }
}

.search-view__item-info {
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 15px;
}

.search-view__input-container--mobile {
  padding: 20px $page-margin-horizontal-mobile;
}

.search-view__item-title {
  @include ut-h5;
}

.search-view__item-add {
  width: 150px;
  transition: none;
}

.search-view__item-description {
  @include ut-body;

  margin-right: 5px;

  &--mobile {
    @extend .search-view__item-description;
    color: $ut-shade--s20;
  }
}

.search-view__empty {
  text-align: center;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $ut-charcoal;
}

.search-view__title {
  @include ut-h5;
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid $ut-limestone--t40;
  font-weight: bold !important;

  @include mobile {
    padding: 0 15px 15px;
    margin-bottom: 0;
  }
}

.search-view__subtitle {
  @include ut-body;
  border-bottom: 1px solid $ut-limestone--t40;
  padding: 25px 0;

  @include mobile {
    padding: 0 15px 15px;
  }
}

.search-view__loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 2;

  display: flex;
  justify-content: center;
  padding-top: 60px;
}

.search-view__loading-spinner {
  width: 40px;
  height: 40px;
}
