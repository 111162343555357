@import 'src/resources';

.ut-h1 {
  @include ut-h1;
}

.ut-h2 {
  @include ut-h2;
}

.ut-h3 {
  @include ut-h3;
}

.ut-h4 {
  @include ut-h4;
}

.ut-h5 {
  @include ut-h5;
}

.ut-h6 {
  @include ut-h6;
}

body,
button,
input,
select,
textarea {
  @include ut-body;
  @include font-franklin;
  font-size: 100%;
  color: $ut-charcoal--s40;
}
