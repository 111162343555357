@import 'src/resources';

.mob-amount-page {
  &__title {
    @include ut-h3;

    text-align: center;

    margin: 22.5px 0 17px;
  }

  &__gift-count {
    @include mobile-header-bold;

    color: $ut-bluebonnet;

    text-align: center;

    margin-bottom: 11px;
  }
}
