/*** Text Utilities ***/
@mixin no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Forces a text element to one line when empty
@mixin has-height-when-empty {
  &:after {
    content: '\200b';
    visibility: hidden;
  }
}

// Based on https://github.com/sass/sass/issues/2378#issuecomment-433868712
@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin nav-dropdown {
  background: $gray-white;
  border: $border;
  border-radius: 5px;
  min-width: 200px;

  position: absolute;
  top: 10px;

  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.05);

  list-style-type: none;
  padding-inline-start: 0;
  z-index: 1;
}

@mixin button-unstyled {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

@mixin list-unstyled {
  list-style: none;
  padding-left: 0;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

@mixin remove-ms-clear-button {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

@mixin foundation-fix-input {
  background-color: transparent !important;
  box-shadow: none !important;
  display: initial;

  &:focus {
    border: none;
    outline: none;
  }
}

@mixin autohide-scrollbar-windows {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@mixin modal-child {
  width: 100%;

  background-color: transparent;
}

@mixin arrow-up($color) {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid $color;
}
