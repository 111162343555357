$ut-burntorange: #bf5700;
$ut-charcoal: #333f48;
$ut-white: #ffffff;
$ut-tangerine: #f8971f;
$ut-sunshine: #ffd600;
$ut-cactus: #a6cd57;
$ut-turtlepond: #579d42;
$ut-turquoise: #00a9b7;
$ut-bluebonnet: #005f86;
$ut-limestone: #d6d2c4;
$ut-shade: #9cadb7;
$ut-bluebonnet--s20: #074d6a;
$ut-turquoise--s20: #138791;
$ut-shade--t20: #f9fafb;
$ut-shade--t40: #e6ebed;
$ut-shade--t60: #c4cdd4;
$ut-shade--s20: #7d8a92;
$ut-shade--s40: #5e686e;
$ut-shade--s60: #3e4549;
$ut-turtlepond--s20: #487d39;
$ut-burntorange--s20: #9d4700;
$ut-charcoal--t20: #ebeced;
$ut-charcoal--t40: #c2c5c8;
$ut-charcoal--t60: #858c91;
$ut-charcoal--s40: #1f262b;
$ut-limestone--t20: #fbfbf9;
$ut-limestone--t40: #f2f1ed;
$ut-limestone--t60: #e6e4dc;
$ut-limestone--s20: #aba89e;
$ut-limestone--s40: #807e76;
$ut-limestone--s60: #56544e;

$ut-error: #cd2026;
$ut-red: #bd1823;
