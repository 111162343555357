@import 'src/resources';

@mixin container_bg {
  @include page-layout-transitions;
  background-color: $ut-burntorange;
}

.container {
  @include page-layout-transitions;
  position: fixed;
  right: 0;
  top: 0;

  width: 100%;
  height: $navigation-height;
  z-index: $z-index-header-sidebar;
  color: $ut-white;

  @include mobile {
    @include container_bg;
  }

  &--pledge {
    @include mobile {
      background-color: transparent;

      &.container_bg--pledge-mobile {
        @include container_bg;
      }
    }
  }
}

.sidebar-open {
  @include page-layout-transitions;
  right: $sidebar_width;
}

.container_bg {
  @include container_bg;
}

.menu_container {
  position: relative;
  width: 80%;
  max-width: 1170px;
  height: 100%;
  line-height: 23px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;

  @include mobile {
    width: 100%;

    padding-left: $navigation-padding-horizontal-mobile;
    padding-right: $navigation-padding-horizontal-mobile;
  }
}

.menu_logo {
  $menu-offset: 3px;

  position: absolute;
  top: calc(50% + #{$menu-offset});
  left: $menu-offset;
  transform: translateY(-50%);
  width: 338px;
  max-width: 338px;

  > a {
    &:focus {
      outline: 1px dotted $ut-shade;
    }
  }

  @include mobile {
    height: auto;

    width: 70%;
    left: 5%;
  }
}

.menu-nav {
  margin-left: 45%;
}

.menu {
  margin-bottom: -8px;
  display: flex;
  line-height: 1.7em;

  @include mobile {
    display: none;
  }

  &.menu_scrolled {
  }
}

.menu-item {
  padding: 0 11px 19px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-weight: 600;
  position: relative;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  a {
    @include hover-transition(0.4s);

    line-height: 1em;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    color: $ut-white;

    position: relative;

    &:focus {
      outline: 1px dotted $ut-shade;
    }
  }

  :after {
    transition: 0.3s ease-out;
    content: ' ';
    display: block;

    position: absolute;
    width: 0%;
    z-index: -1;
    height: 20px;
    bottom: 0;
    background-image: linear-gradient(180deg, transparent 65%, #ffd60080 0);
  }

  &:hover,
  &.active {
    :after {
      width: 100%;
    }
  }
}

.top-navigation {
  @include hover-transition(0.4s);
  position: relative;
  width: 32px;
  padding: 19px 0;
  margin-bottom: -8px;

  @include mobile {
    margin-bottom: 0;
  }
}

.mobile_menu_bar {
  cursor: pointer;
  padding-top: 2px;
  &:before {
    @include sidebar-hamburger;
  }
  &.open {
    &:before {
      @include sidebar-close;
    }
  }
}
