@font-face {
  font-family: 'LibreFranklin';

  src: url(../../../../assets/fonts/LibreFranklin/LibreFranklin-Regular.woff)
      format('woff'),
    url(../../../../assets/fonts/LibreFranklin/LibreFranklin-Regular.woff2)
      format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LibreFranklin';

  src: url(../../../../assets/fonts/LibreFranklin/LibreFranklin-Medium.woff)
      format('woff'),
    url(../../../../assets/fonts/LibreFranklin/LibreFranklin-Medium.woff2)
      format('woff2');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'LibreFranklin';

  src: url(../../../../assets/fonts/LibreFranklin/LibreFranklin-SemiBold.woff)
      format('woff'),
    url(../../../../assets/fonts/LibreFranklin/LibreFranklin-SemiBold.woff2)
      format('woff2');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'LibreFranklin';

  src: url(../../../../assets/fonts/LibreFranklin/LibreFranklin-Bold.woff)
      format('woff'),
    url(../../../../assets/fonts/LibreFranklin/LibreFranklin-Bold.woff2)
      format('woff2');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'LibreFranklin';

  src: url(../../../../assets/fonts/LibreFranklin/LibreFranklin-ExtraBold.woff)
      format('woff'),
    url(../../../../assets/fonts/LibreFranklin/LibreFranklin-ExtraBold.woff2)
      format('woff2');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'ElegantIcons';
  src: url(../../../../assets/fonts/ElegantIcons/ElegantIcons.woff)
    format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'FontAwesome';

  src: url(../../../../assets/fonts/FontAwesome/fa-solid-900.woff)
      format('woff'),
    url(../../../../assets/fonts/FontAwesome/fa-solid-900.woff2) format('woff2');
  font-style: normal;
  font-weight: 100;
}
