$page-margin-horizontal: 130px;
$page-margin-horizontal-mobile: 15px;

$content-padding-vertical: 70px;

$navigation-height: 70px;
$navigation-padding-horizontal-mobile: 40px;

$footer-height: 107px;

$modal-padding: 20px;

@mixin widthColumnLeft {
  width: 55%;
}

@mixin widthColumnRight {
  width: 45%;
  max-width: 550px;
}
