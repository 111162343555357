@import 'src/resources';

$button-margin-bottom: 25px;

.mob-frequency-page {
  &__content {
    display: block;
  }

  &__body {
    padding: 22px 22px $button-margin-bottom;

    display: flex;
    align-items: center;
    flex-direction: column;

    height: 100%;
  }

  &__button-group {
    display: flex;
    flex-direction: column;

    margin: auto 0;
  }

  &__title {
    @include ut-h3;

    text-align: center;
  }

  &__gift-count {
    @include mobile-header-bold;

    color: $ut-bluebonnet;

    text-align: center;
  }

  &__tool-tip {
    @include ut-body;

    margin: 40px 0 32px;
  }

  &__button {
    &:not(:last-of-type) {
      margin-bottom: $button-margin-bottom;
    }
  }

  &__bottom {
    padding: 20px 20px 51px;

    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;

    background: $ut-white;
    text-align: center;
  }

  &__divider {
    border-top: 1px solid $ut-burntorange;
    padding-bottom: 20px;
  }

  &__next {
    width: 100%;
    padding: 10px;
  }
}
