@import 'src/resources';

.ogp-checkbox {
  cursor: pointer;
}

.ogp-checkbox__input {
  position: absolute;
  left: -999em;
}

@mixin checked-style($color) {
  cursor: pointer;
  @include checkbox-label-style(false, $color);

  &--checked {
    @include checkbox-label-style(true, $color);
  }
}

.ogp-checkbox__label {
  @include checked-style($ut-bluebonnet);
}

.ogp-checkbox--brand-color {
  @include checked-style($ut-burntorange);
}
