@import 'src/resources';

.alert-modal__window {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
