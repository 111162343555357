@mixin font-franklin {
  font-family: 'LibreFranklin', Arial, Helvetica, sans-serif;
}

@mixin ut-h1 {
  font-size: 3.0625rem;
  line-height: 1.143;
  font-weight: 600;

  @include mobile {
    font-size: 2.4375rem;
  }
}

@mixin ut-h2 {
  font-size: 2.25rem;
  line-height: 1.154;
  font-weight: 600;

  @include mobile {
    font-size: 2.0625rem;
  }
}

@mixin ut-h3 {
  font-size: 1.5625rem;
  line-height: 1.25;
  font-weight: 500;

  @include mobile {
    font-size: 1.1875rem;
  }
}

@mixin ut-h4 {
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: normal;

  @include mobile {
    font-size: 1rem;
  }
}

@mixin ut-h5 {
  font-size: 1rem;
  line-height: 1.5625;
  font-weight: 600;

  @include mobile {
    font-size: 0.875rem;
    font-weight: 400;
  }
}

@mixin ut-h6 {
  font-size: 0.8125rem;
  line-height: 1.385;
  font-weight: 600;
}

@mixin ut-body {
  font-size: 1rem;
  line-height: 1.5625;
  font-weight: 400;

  @include mobile {
    font-size: 0.8125rem;
  }
}

@mixin ut-body--medium {
  @include ut-body();

  font-weight: 500;
}

@mixin ut-link {
  font-size: 0.825rem;

  &:focus {
    outline: 1px dotted $ut-shade;
  }
}

@mixin ut-cta-link {
  font-weight: 700;
  letter-spacing: 0.35px;
}

@mixin ut-label {
  @include font-franklin;
  font-size: 0.875rem;
  line-height: 17px;
  font-weight: 600;
}

@mixin mobile-header-bold {
  @include ut-h6;
  font-weight: 700;
}

@mixin embed-title {
  @include font-franklin;
  font-size: 1.0625rem;
  font-weight: 800;
}
