@import 'src/resources';

.embed-message {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;

  background: rgba($ut-charcoal, 0.75);

  z-index: $z-index-modal;

  &__window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    max-width: 80vw;

    display: flex;
    flex-direction: column;

    background: $ut-white;
    border-radius: 5px;
  }

  &__title-container {
    padding: 25px 25px 15px 20px;
    border-bottom: 1px solid $ut-limestone--t40;
  }

  &__title {
    @include ut-body--medium;
  }

  &__message-container {
    @include ut-body;
    padding: 25px 25px 20px 20px;
  }

  &__button-container {
    padding: 20px 25px 10px;
    text-align: right;
    border-top: 1px solid $ut-limestone--t40;
  }
}
