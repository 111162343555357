$mobile: 920px;
$desktop-small: 1216px;

@mixin desktop {
  @media (min-width: #{$mobile}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

@mixin desktop-small {
  @media (max-width: #{$desktop-small}) {
    @content;
  }
}
