@import 'src/resources';

$hero-image-height: 172px;

@mixin header-image($url) {
  background-image: linear-gradient(
      180deg,
      rgba($ut-charcoal, 0.71) 0%,
      rgba($ut-burntorange, 0.84) 100%
    ),
    url($url);
}

.header-container {
  color: $ut-white;
  background-color: $ut-white;
  background-position: 50%;
  background-size: cover;

  padding: $hero-image-height 0 3%;
  position: relative;

  @include mobile {
    height: $navigation-height;

    padding: 0;
    background-image: none;
  }

  &--hidden {
    height: 0;
    padding: 0;
  }

  &--ogp {
    @include header-image('../../../../assets/images/hero-ogp.jpg');
  }

  &--pledge {
    @include header-image('../../../../assets/images/hero-pledge.jpg');

    background-position-y: 0;

    @include mobile {
      @include header-image('../../../../assets/images/stone-texture.png');

      height: $hero-image-height;
    }
  }
}

.header-content {
  position: relative;
  width: 80%;
  max-width: 1170px;
  margin: auto;
  border: 0;
  outline: 0;
  font-size: 100%;

  @include mobile {
    display: none;
  }
}

.header-divider {
  position: relative;
  max-width: 10%;
  height: 23px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    border-top: 15px solid $ut-tangerine;
  }

  &:after {
    content: '';
    display: table;
  }
}

.header-title {
  padding-bottom: 10px;
  line-height: 1.1em;
  font-size: 39px;
  font-weight: bold;
}

.header-body {
  max-width: 50%;
  line-height: 25.5px;
  font-size: 1.0625em;
}

.header__pledge-mobile-title {
  display: none;
  padding-left: 10px;

  @include mobile {
    position: absolute;
    display: block;
    font-weight: 800;
    bottom: 24px;
    left: $navigation-padding-horizontal-mobile;
  }
}
