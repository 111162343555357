@import 'src/resources';

.mob-pledge-summary {
  @include mobile-summary;

  &__gift-info {
    text-align: left;
    width: auto;
    max-width: 75%;
    padding-left: 10px;
  }

  &__gift-amount {
    max-width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
