@import 'src/resources';

$short-field-height: 40px;

$currency-border-radius: 10px;
$currency-left-side-width: 78px;

.ogp-text-input {
  margin: 0.25em 0;
}

.ogp-text-input__container {
  @include input-container-style;

  &--currency {
    &:before {
      @include ut-h6;
      font-size: 1rem;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $currency-left-side-width;
      background: $ut-limestone--t40;
      left: 1px;
      top: 1px;
      bottom: 1px;

      content: '$';
      height: auto;
      border-radius: $currency-border-radius 0 0 $currency-border-radius;
    }
  }
}

.ogp-text-input__label {
  @include ut-label;
  @include no-wrap;
}

.ogp-text-input__field {
  @include input-field-style;

  &:focus {
    background-color: $ut-limestone--t20;

    outline: none;
  }

  &--search {
    background: $ut-white;
    border-radius: $short-field-height / 2;
    height: $short-field-height;
    border-color: $ut-shade--t60;
    color: $ut-charcoal;

    @include placeholder {
      color: $ut-shade--s40;
      opacity: 1;
    }
    @include mobile {
      @include placeholder {
        @include ut-h5;

        line-height: inherit;

        @include firefox {
          /* Firefox doesn't center the placeholder 
             unless we force the field height */
          line-height: $short-field-height;
        }
      }
    }
  }

  &--currency {
    @include ut-h6;
    font-size: 1rem;
    height: 20px;
    border-radius: $currency-border-radius;
    height: $short-field-height;
    padding-left: $currency-left-side-width + 16px;

    @include placeholder {
      @include ut-h6;
      line-height: revert;
      height: 100%;
      color: $ut-shade--s20;
    }

    @supports (-webkit-touch-callout: none) {
      // Safari only
      line-height: normal;
      @include placeholder {
        line-height: normal;
        transform: translateY(10px);
      }
    }
  }
}

.ogp-text-input__error-msg {
  color: $ut-error;
  font-size: 0.75rem;
  font-weight: 600;
  height: 0;
}

.intl-tel-input {
  width: 100%;

  .selected-flag:focus {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    border: 0;
    background-color: $ut-limestone--t20;

    outline: none;
  }
}
