@import 'src/resources';

$footer-height: 135px;

.mob-search-page {
  &__content {
    background: $ut-limestone--t20;
    min-height: 100vh;
  }

  &__pledge {
    padding: 20px $page-margin-horizontal-mobile;
    text-align: center;
  }

  &__bottom {
    height: $footer-height;
    padding: 0 20px 20px;

    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;

    background: $ut-white;
    text-align: center;
  }

  &__divider {
    border-top: 1px solid $ut-burntorange;
    padding-bottom: 20px;
  }

  &__gift-count {
    @include mobile-header-bold;

    color: $ut-bluebonnet;
    padding-bottom: 20px;
  }
}
