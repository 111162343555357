@import 'src/resources';

html {
  background: transparent;
}

.ogp-app {
  min-height: 100vh;
  background: $ut-white;
  position: relative;
  right: 0;
  @include page-layout-transitions;

  &-container {
    @include mobile {
      display: flex;
      flex-direction: column;
      height: calc(100vh - #{$navigation-height});
    }
  }
}

.sidebar-open {
  right: $sidebar-width;
  @include page-layout-transitions;
}

.html--lock-scroll {
  overflow: hidden;
}
