@import './_colors.scss';

@mixin checkbox-label-style($checked, $checked-color) {
  &:before {
    background: #fff;
    background-color: rgb(255, 255, 255);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-image: none;
    box-shadow: 0 0 0 1.5px #5e686e;
    content: ' ';
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    margin-right: 0.75em;
    text-indent: 0.15em;
    vertical-align: middle;
    align-self: center;
    width: 2rem;
    border-radius: 8px;
    flex-shrink: 0;
  }

  @if $checked {
    &:before {
      background-color: $checked-color;
      background-image: url('../../../../assets/images/icons/checkmark.svg');
      background-position: 50%;
      background-repeat: no-repeat;
      box-shadow: 0 0 0 1.5px$checked-color;
    }
  }
}
